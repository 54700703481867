import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
//@ts-ignore
import videoWalkThrough from "../../videos/apple-walkthrough.mp4"

const AtsbIndex = ({ location }) => {
  return (
    <Layout location={location}>
      <div className="work">
        <div className="work__header">
          <div>
            <h1>Apple: Travel Safety Briefing</h1>
            <p className="work__strap">React, NodeJS</p>
          </div>
          <div className="work__branding">
            <StaticImage
              src="../../images/apple/logo.png"
              alt="Apple Logo"
              width={100}
              height={100}
            />
          </div>
        </div>
      </div>

      <h2>Overview</h2>

      <p>
        Apple’s Global Security tools team was under pressure to replace a
        critical third-party system that was being mothballed. I was part of a
        team that was trusted us to drive the delivery of a new system, build
        consensus and augment their existing teams.
      </p>

      <h2>💪 Responsibilities</h2>

      <p>
        I was the lead developer on the project within the{" "}
        <a href="https://wemakewaves.digital">We Make Waves</a> agency, my main
        responsibilities included:
      </p>
      <ul>
        <li>
          Running story mapping workshops with key stake holders within the
          Apple Security team to form consensus on what we were aiming to build
          &amp; to shape a delivery roadmap from that.
        </li>
        <li>
          Leading a team of 3 in an agile approach to iterate through the road
          map and integrate with Apples Travel team.
        </li>
      </ul>

      <h3>✨ Noteworthy</h3>

      <ul>
        <li>Using Swagger to collaborate on API specifications across teams</li>
        <li>
          BDD Feature files anchoring entire test suite written with
          stakeholders
        </li>
        <li>
          Introducing React to Apple internal teams which we heavily Emberjs
          focused.
        </li>
      </ul>

      <h2>👀 At a glance</h2>
      <ul className="work__at-a-glance">
        <li>NextJS</li>
        <li>ReactJs</li>
        <li>Typescript</li>
        <li>Koa</li>
        <li>NodeJS</li>
        <li>Learna</li>
        <li>TDD</li>
        <li>BDD</li>
      </ul>

      <h2>🚶 Walkthrough</h2>
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{ maxWidth: "100%", marginBottom: "1rem" }}
      >
        <source src={videoWalkThrough} type="video/mp4" />
      </video>

      <h2>🚀 Impact</h2>

      <ul>
        <li>
          Delivered an MVP to production within 3 months, ensuring employees
          could still receiving travel briefings.
        </li>
        <li>
          Documented the application with full test coverage allowing Apple
          internal teams to continue development.
        </li>
      </ul>
    </Layout>
  )
}

export default AtsbIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo title="Apple: Travel Safety Briefing" noIndex={true} />
)
